
<template>

  <div>
  <v-parallax
          dark
          src="https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg"
  >
    <v-row
            align="center"
            justify="center"
    >

      <v-col
              class="text-center"
              cols="12"
      >
        <h1 class="display-4 font-weight-thin mb-4">
          Лайнер
        </h1>
        <h4 class="subheading">
          Прогрессивная медицинская система!
        </h4>


        <!--v-row>
          <v-col
                  cols="12"
                  sm="4"
          >
        <v-card
                class="mt-6 pa-2"
                elevation="10"
                outlined
                :loading="loading"
        >


          <v-list-item-group
                  shaped
                  color="primary"
                  v-model="selectedItem"
          >
            <v-list-item
                    v-for="(e,i) in arr"
                    :key="i"
                    @click="onNext(i)"
                    :class="{disabled: e.FREE=='0'}"
                    :disabled="e.FREE=='0'"
            >
              <v-list-item-icon>
                <v-icon v-if="e.FREE=='0'"
                        color="gray"
                >
                  {{iconNo}}
                </v-icon>
                <v-icon v-else
                        color="green"
                >
                  {{iconYes}}
                </v-icon>

              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="e.NNAME"></v-list-item-title>
                <v-list-item-subtitle v-text="e.REM"></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-card>
          </v-col>
          <v-col
                  cols="12"
                  sm="4"
          >
            <v-card
                    class="mt-6 pa-2"
                    elevation="10"
                    outlined
                    :loading="loading"
            >


              <v-list-item-group
                      shaped
                      color="primary"
                      v-model="selectedItem"
              >
                <v-list-item
                        v-for="(e,i) in arr"
                        :key="i"
                        @click="onNext(i)"
                        :class="{disabled: e.FREE=='0'}"
                        :disabled="e.FREE=='0'"
                >
                  <v-list-item-icon>
                    <v-icon v-if="e.FREE=='0'"
                            color="gray"
                    >
                      {{iconNo}}
                    </v-icon>
                    <v-icon v-else
                            color="green"
                    >
                      {{iconYes}}
                    </v-icon>

                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="e.NNAME"></v-list-item-title>
                    <v-list-item-subtitle v-text="e.REM"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-card>
          </v-col>
          <v-col
                  cols="12"
                  sm="4"
          >
            <v-card
                    class="mt-6 pa-2"
                    elevation="10"
                    outlined
                    :loading="loading"
            >


              <v-list-item-group
                      shaped
                      color="primary"
                      v-model="selectedItem"
              >
                <v-list-item
                        v-for="(e,i) in arr"
                        :key="i"
                        @click="onNext(i)"
                        :class="{disabled: e.FREE=='0'}"
                        :disabled="e.FREE=='0'"
                >
                  <v-list-item-icon>
                    <v-icon v-if="e.FREE=='0'"
                            color="gray"
                    >
                      {{iconNo}}
                    </v-icon>
                    <v-icon v-else
                            color="green"
                    >
                      {{iconYes}}
                    </v-icon>

                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="e.NNAME"></v-list-item-title>
                    <v-list-item-subtitle v-text="e.REM"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-card>
          </v-col>
        </v-row-->

      </v-col>
    </v-row>
    </v-parallax>



  <v-container fluid >

    <h1 align="center">
      Преимущества
    </h1>

    <v-row>
      <v-col
              cols="12"
              sm="2"
      >


        <v-card
                class="mx-auto"
                max-width="700"
                elevation="10"
                rounded="xl"
        >
          <v-img
                  src="/img/home/pers700x467.jpg"
                  height="200px"
          ></v-img>

          <v-card-title>
            Удобно
          </v-card-title>

          <v-card-subtitle>
            Быстрый интерфейс
          </v-card-subtitle>

          <v-card-actions>
            <v-btn
                    color="orange lighten-2"
                    text
            >
              Подробнее
            </v-btn>

          </v-card-actions>
        </v-card>
      </v-col>


      <v-col
              cols="12"
              sm="2"
      >
        <v-card
                class="mx-auto"
                max-width="700"
                elevation="10"
                rounded="xl"
        >
          <v-img
                  src="/iages/home/ai700x467.jpg"
                  height="200px"
          ></v-img>

          <v-card-title>
            Сопровождение
          </v-card-title>

          <v-card-subtitle>
            Система <q>Все включено!</q>
          </v-card-subtitle>

          <v-card-actions>
            <v-btn
                    color="orange lighten-2"
                    text
            >
              Подробнее
            </v-btn>

          </v-card-actions>
        </v-card>
      </v-col>

      <v-col
              cols="12"
              sm="2"
      >
        <v-card
                class="mx-auto"
                max-width="700"
                elevation="10"
                rounded="xl"
        >
          <v-img
                  src="/img/home/star377x250.jpg"
                  height="200px"
          ></v-img>

          <v-card-title>
            Без картриджей
          </v-card-title>

          <v-card-subtitle>
            Использование термопринтеров
          </v-card-subtitle>

          <v-card-actions>
            <v-btn
                    color="orange lighten-2"
                    text
            >
              Подробнее
            </v-btn>

          </v-card-actions>
        </v-card>
      </v-col>



      <v-col
              cols="12"
              sm="2"
      >

        <v-card
                class="mx-auto"
                max-width="700"
                elevation="10"
                rounded="xl"
        >
          <v-img
                  src="/img/home/bc375x250.jpg"
                  height="200px"
          ></v-img>

          <v-card-title>
            Штрих-кода
          </v-card-title>

          <v-card-subtitle>
            Быстрый поиск
          </v-card-subtitle>

          <v-card-actions>
            <v-btn
                    color="orange lighten-2"
                    text
            >
              Подробнее
            </v-btn>

          </v-card-actions>
        </v-card>
      </v-col>


      <v-col
              cols="12"
              sm="2"
      >
        <v-card
                class="mx-auto"
                max-width="700"
                elevation="10"
                rounded="xl"
        >
          <v-img
                  src="/img/home/cash375x250.jpg"
                  height="200px"
          ></v-img>

          <v-card-title>
            Интеграция
          </v-card-title>

          <v-card-subtitle>
            Интеграция с кассовыми аппаратами и пр.
          </v-card-subtitle>

          <v-card-actions>
            <v-btn
                    color="orange lighten-2"
                    text
            >
              Подробнее
            </v-btn>

          </v-card-actions>
        </v-card>
      </v-col>

      <v-col
              cols="12"
              sm="2"
      >
        <v-card
                class="mx-auto"
                max-width="700"
                elevation="10"
                rounded="xl"
        >
          <v-img
                  src="/img/home/eq700x467.jpg"
                  height="200px"
          ></v-img>

          <v-card-title>
            Электронная очередь
          </v-card-title>

          <v-card-subtitle>
            Отстутствие очередей
          </v-card-subtitle>

          <v-card-actions>
            <v-btn
                    color="orange lighten-2"
                    text
            >
              Подробнее
            </v-btn>

          </v-card-actions>
        </v-card>
      </v-col>


    </v-row>






    <v-row>
      <v-col
              cols="12"
              sm="6"
      >
        <v-hover
                v-slot="{ hover }"
                open-delay="200"
        >
          <v-card
                  :elevation="hover ? 16 : 2"
                  :class="{ 'on-hover': hover }"
                  class="mx-auto"
                  height="350"
                  max-width="350"
          >
            <v-card-text class="font-weight-medium mt-12 text-center subtitle-1">
              Open Delay (Mouse enter)
            </v-card-text>
          </v-card>
        </v-hover>
      </v-col>

      <v-col
              cols="12"
              sm="6"
      >
        <v-hover
                v-slot="{ hover }"
                close-delay="200"
        >
          <v-card
                  :elevation="hover ? 16 : 2"
                  :class="{ 'on-hover': hover }"
                  class="mx-auto"
                  height="350"
                  max-width="350"
          >
            <v-card-text class="font-weight-medium mt-12 text-center subtitle-1">
              Close Delay (Mouse leave)
            </v-card-text>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>



    <v-row>
    <v-col
            cols="12"
            sm="6"
    >
        <v-card
                class="mt-6 pa-2 xl9"
                elevation="10"
                outlined
                :loading="loading"

        >


          <v-list-item-group
                  shaped
                  color="primary"
                  v-model="selectedItem"
          >
            <v-list-item
                    v-for="(e,i) in arr"
                    :key="i"
                    @click="onNext(i)"
                    :class="{disabled: e.FREE=='0'}"
                    :disabled="e.FREE=='0'"
            >
              <v-list-item-icon>
                <v-icon v-if="e.FREE=='0'"
                        color="gray"
                >
                  {{iconNo}}
                </v-icon>
                <v-icon v-else
                        color="green"
                >
                  {{iconYes}}
                </v-icon>

              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="e.NNAME"></v-list-item-title>
                <v-list-item-subtitle v-text="e.REM"></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-card>
    </v-col>
    <v-col
            cols="12"
            sm="6"
    >
      <v-card
              class="mt-6 pa-2"
              elevation="10"
              outlined
              :loading="loading"
      >


        <v-list-item-group
                shaped
                color="primary"
                v-model="selectedItem"
        >
          <v-list-item
                  v-for="(e,i) in arr"
                  :key="i"
                  @click="onNext(i)"
                  :class="{disabled: e.FREE=='0'}"
                  :disabled="e.FREE=='0'"
          >
            <v-list-item-icon>
              <v-icon v-if="e.FREE=='0'"
                      color="gray"
              >
                {{iconNo}}
              </v-icon>
              <v-icon v-else
                      color="green"
              >
                {{iconYes}}
              </v-icon>

            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="e.NNAME"></v-list-item-title>
              <v-list-item-subtitle v-text="e.REM"></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-card>
    </v-col>
    </v-row>

    <!--v-carousel
            cycle
            height="400"
            hide-delimiter-background
            show-arrows-on-hover
            dark
    >
      <v-carousel-item
              v-for="(slide, i) in slides"
              :key="i"
      >
        <v-sheet
                 :color="colors[i]"
                  height="100%"
        >
          <v-row
                  class="fill-height"
                  align="center"
                  justify="center"
          >
            <div class="display-3" >
              {{ slide.txt }}
            </div>
          </v-row>
        </v-sheet>
      </v-carousel-item>
    </v-carousel-->

    <v-row>
      <v-col
              cols="12"
              sm="6"
      >
        <v-parallax src="https://cdn.vuetifyjs.com/images/parallax/material.jpg"></v-parallax>
        <v-card
                class="mt-6 pa-2"
                elevation="10"
                outlined

        >
          <v-card
                  outlined
                  :loading="loading"
          >

            <v-card-text>
              <div>Word of the Day</div>
              <p class="display-1 text--primary">
                el·ee·mos·y·nar·y
              </p>
              <p>adjective</p>
              <div class="text--primary">
                relating to or dependent on charity; charitable.<br>
                "an eleemosynary educational institution."
              </div>
            </v-card-text>
          </v-card>
        </v-card>
        <v-card
                elevation="0"
        >
          <v-card
                  outlined
                  :loading="loading"
          >

            <v-card-text>
              <div>Word of the Day</div>
              <p class="display-1 text--primary">
                el·ee·mos·y·nar·y
              </p>
              <p>adjective</p>
              <div class="text--primary">
                relating to or dependent on charity; charitable.<br>
                "an eleemosynary educational institution."
              </div>
            </v-card-text>
          </v-card>
        </v-card>

      </v-col>

      <v-col
              cols="12"
              sm="6"
      >
        <v-card
                elevation="0"
        >
          <v-card
                  outlined
                  :loading="loading"
          >

            <v-card-text>
              <div>Word of the Day</div>
              <p class="display-1 text--primary">
                el·ee·mos·y·nar·y
              </p>
              <p>adjective</p>
              <div class="text--primary">
                relating to or dependent on charity; charitable.<br>
                "an eleemosynary educational institution."
              </div>
            </v-card-text>
          </v-card>
        </v-card>
        <v-card
                elevation="0"
        >
          <v-card
                  outlined
                  :loading="loading"
          >

            <v-card-text>
              <div>Word of the Day</div>
              <p class="display-1 text--primary">
                el·ee·mos·y·nar·y
              </p>
              <p>adjective</p>
              <div class="text--primary">
                relating to or dependent on charity; charitable.<br>
                "an eleemosynary educational institution."
              </div>
            </v-card-text>
          </v-card>
        </v-card>

      </v-col>



      <v-col
              cols="12"
              sm="6"
      >
        <v-card
                elevation="0"
        >
          <v-card
                  outlined
                  :loading="loading"
          >

            <v-card-text>
              <div>Word of the Day</div>
              <p class="display-1 text--primary">
                el·ee·mos·y·nar·y
              </p>
              <p>adjective</p>
              <div class="text--primary">
                relating to or dependent on charity; charitable.<br>
                "an eleemosynary educational institution."
              </div>
            </v-card-text>
          </v-card>
        </v-card>
        <v-card
                elevation="0"
        >
          <v-card
                  outlined
                  :loading="loading"
          >

            <v-card-text>
              <div>Word of the Day</div>
              <p class="display-1 text--primary">
                el·ee·mos·y·nar·y
              </p>
              <p>adjective</p>
              <div class="text--primary">
                relating to or dependent on charity; charitable.<br>
                "an eleemosynary educational institution."
              </div>
            </v-card-text>
          </v-card>
        </v-card>

      </v-col>







      <v-col
              cols="12"
              sm="6"
      >
        <v-card
                class="pa-2"
                elevation="0"
        >
          <h2>222222222</h2>
          <v-card
                  class="mt-6 pa-2"
                  elevation="10"
                  outlined
                  :loading="loading"
          >


            <v-list-item-group
                    shaped
                    color="primary"
            >
              <v-list-item
                      v-for="(e,i) in arr"
                      :key="i"
              >
                <v-list-item-icon>
                  <v-icon v-if="e.FREE=='0'"
                          color="gray"
                  >
                    {{iconNo}}
                  </v-icon>
                  <v-icon v-else
                          color="green"
                  >
                    {{iconYes}}
                  </v-icon>

                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="e.NNAME"></v-list-item-title>
                  <v-list-item-subtitle v-text="e.REM"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-card>
        </v-card>
      </v-col>

      <v-col
              cols="12"
              sm="12"
      >
        <v-card
                class="pa-2"
                elevation="0"
        >
          <v-card
                  class="mt-6 pa-2"
                  elevation="0"
                  outlined
          >


            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>fasfafasfas</v-list-item-title>
                <v-list-item-subtitle >gsaf ;asd asad jf;</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title >fsafafasd</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title >faasfasasfs</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title >sdfasf asasf</v-list-item-title>
                <v-list-item-subtitle > asfasfasfas fasdf asd f</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>


          </v-card>
        </v-card>
      </v-col>

    </v-row>
  </v-container>
  </div>
</template>

<script>

  import {mdiAccountCheck , mdiAccountCancel} from '@mdi/js';
  import {addNavbarTab} from "@/components/app/js/globals";
  export default {
    name: 'Med',
    data: () => ({
      name: 'Med',
      header: 'Выберите врача',
      loading: false,
      log: true,
      alert: true,
      selectedItem: null,
      alertError: {
        show: false,
        text: null
      },
      alertWarning: {
        show: false,
        text: null
      },
      iconYes: mdiAccountCheck ,
      iconNo: mdiAccountCancel,
      arr: [
        {
            FREE : '1',
            NNAME : 'Вахрушев А.В. ( каб 220)  ПЕДИАТР', //Upper, т.к. так передается из REST API
            NPP: '1595',
            REM: 'Есть талоны с 02.11.2020 08:00'
        },
        {
          FREE : '1',
          NNAME : 'Вахрушев А.В. ( каб 220)  ПЕДИАТР', //Upper, т.к. так передается из REST API
          NPP: '2595',
          REM: 'Есть талоны с 02.11.2020 08:00'
        },
        {
          FREE : '1',
          NNAME : 'Вахрушев А.В. ( каб 220)  ПЕДИАТР', //Upper, т.к. так передается из REST API
          NPP: '3595',
          REM: 'Есть талоны с 02.11.2020 08:00'
        },
        {
          FREE : '1',
          NNAME : 'Вахрушев А.В. ( каб 220)  ПЕДИАТР', //Upper, т.к. так передается из REST API
          NPP: '4595',
          REM: 'Есть талоны с 02.11.2020 08:00'
        },

      ],
      items: [
        {
          color: '#1F7087',
          src: 'https://cdn.vuetifyjs.com/images/cards/foster.jpg',
          title: 'Supermodel',
          artist: 'Foster the People',
        },
        {
          color: '#952175',
          src: 'https://cdn.vuetifyjs.com/images/cards/halcyon.png',
          title: 'Halcyon Days',
          artist: 'Ellie Goulding',
        },
      ],
      alignments: [
        'start',
        'center',
        'end',
      ],
      colors: [
        'indigo',
        'warning',
        'pink darken-2',
        'red lighten-1',
        'deep-purple accent-4',
        'deep-purple accent-4'
      ],
      slides: [
        {txt: 'Удобно для персонала', src: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg'},
        {txt: 'Сопровождение "Все включено"', src: 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg'},
        {txt: 'Печать без картриджей', src: 'https://cdn.vuetifyjs.com/images/carousel/bird.jpg'},
        {txt: 'Оригинальная система штрих-кодов', src: 'https://cdn.vuetifyjs.com/images/carousel/planet.jpg'},
        {txt: 'Интеграция с кассовым аппаратом',src: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg'},
        {txt: 'Электронная очередь',src: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg'},
      ],
      menuitems: [
        { title: 'Home', icon: 'mdi-home-city' },
        { title: 'My Account', icon: 'mdi-account' },
        { title: 'Users', icon: 'mdi-account-group-outline' },
      ],
      show: false
    })



  }
</script>
