<template>

    <v-container  fluid class="ma-md-auto">

        <SectionHeader :header="caption"/>



        <v-card
                class="mx-auto pa-md-5"
                rounded="xl"
                style="max-width: 800px;"
                elevation="5"

        >
            <v-form
                    ref="form"
                    v-model="form"
                    class="pa-6 pt-6"
            >
                <v-text-field
                        v-model="arr.p_name"
                        filled
                        type="username"
                        label="Login"
                        autofocus
                        :rules="[rules.required]"
                        ref="name"
                        v-on:keyup="onNameEnter"
                ></v-text-field>
                <v-text-field
                        v-model="arr.p_psw"
                        filled
                        :type="pswshow ? 'text' : 'password'"
                        :append-icon="pswshow ? iconEye : iconEyeOff"
                        @click:append="pswshow = !pswshow"
                        label="Пароль"
                        :rules="[rules.required]"
                        ref="psw"
                        v-on:keyup="onPswEnter"
                ></v-text-field>

            </v-form>


            <v-divider></v-divider>
            <v-card-actions class="pa-md-7">
                <v-btn
                        text
                        @click="$refs.form.reset(); $refs['name'].focus();"
                        rounded
                >
                    Очистить
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                        :disabled="!form"
                        class="white--text"
                        color="primary"
                        depressed
                        rounded
                        @click="doLogin()"
                >
                    Вход
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<script>
    import { mdiCheckboxMarked, mdiCheckboxBlankOutline, mdiCheckboxMarkedOutline, mdiEye, mdiEyeOff} from '@mdi/js';
    import SectionHeader from "@/components/app/SectionHeader";
    import {addNavbarTab, hashCode} from "@/components/app/js/globals";
    import {isPswOk, siteUrl, urlUpdates} from "../components/app/js/globals";
    import post from "../components/app/js/post";
    import fs from "fs"

    export default {
        name: "Login",
        data: () => ({
            caption: "Вход",
            form: false,
            pswshow: false,
            iconEye: mdiEye,
            iconEyeOff: mdiEyeOff,
            arr: {
                p_name: '',
                p_psw: ''
            },
            rules: {
                required: v => !!v || 'Поле обязательно для заполнения'
            },
            navtab: {}
        }),
        beforeMount() {
            this.navtab={name: this.$options.name, caption: this.caption}
            addNavbarTab(this,this.navtab)
        },
        components: {
            SectionHeader
        },
        methods: {
            onNameEnter: function(e) {
                if (e.keyCode === 13) {
                    this.$refs["psw"].focus();
                }
                //this.log += e.key;
            },
            onPswEnter: function(e) {
                if (e.keyCode === 13) {
                    this.doLogin()
                }
                //this.log += e.key;
            },
            doLogin() {
                //console.log(this.arr.p_psw,' ',hashCode(this.arr.p_psw))
                if(isPswOk(this.arr.p_name, this.arr.p_psw)) {
                    this.$store.commit('DEL_NAV', this.navtab)
                    this.$store.commit('NAV_LOGIN', true)
                    this.$router.push('/updates')
                }
                this.$refs.form.reset()
                this.$refs["name"].focus();

            }
        }

    }
</script>

<style scoped>

</style>